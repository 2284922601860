<template>
	<div class="loans">
		<e-table ref="tables" v-on:resetSearch="resetSearch" @query-Table-data="handleCurrentChange" v-on:searchParamsChange="searchParamsChange" :total="total" :currentPage="currentPage" :tableCols="loansTableConf" :showOperation="true" :dataOrigin="dataOrigin">
			<template #customOperation>
				<el-col :span="24">
					<el-radio-group  @change="agreeChange" v-model="tabPosition" style="margin-bottom: 30px;">
						<el-radio-button label="dfk">待付款</el-radio-button>
						<el-radio-button label="dcz">待查账</el-radio-button>
						<el-radio-button label="yth">已退还</el-radio-button>
						<el-radio-button label="ydz">已到账</el-radio-button>
					</el-radio-group>
				</el-col>
			</template>
			<template #pict_url="scope">
				<el-popover
						trigger="hover">
					<img height="300" width="300" :src="scope.rowData.pict_url" alt="">
					<img slot="reference" height="80" width="80" :src="scope.rowData.pict_url" alt="">
				</el-popover>
			</template>
			<template #title="scope">
				<el-link class="lh_12" type="primary" :href="scope.rowData.item_url" target="_blank">{{ scope.rowData.title }}</el-link>
			</template>
			<template #operation="scope">
				<div></div>
				<div class="cur_p" @click="dz(scope.rowData.id)">到账</div>
				<div class="cur_p"  @click="th(scope.rowData.id)">退还</div>
			</template>
		</e-table>
		<e-dialog title="" ref="dialogRef">
		</e-dialog>
	</div>
</template>

<script>
import {loansTableConf} from '@data/finance'
export default {
	name: 'Loans',
	data() {
		return {
			loansTableConf,
			dataOrigin: {
				data: [
				]
			},
			tabPosition: 'dfk',
			form: {},
			activeName: "first",
			currentPage: 1,
			pageSize: 10,
			total: 0,
		}
	},
	methods: {
		handleCurrentChange(data){
			this.$refs.tables.loading = true
			this.currentPage = data.currentPage
			this.GetBillLists()
		},
		dz(id){
			let that = this

			this.$confirm('是否到账?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.bill.handle',{
					id:id}).
				then(function () {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message({
						type: 'success',
						message: '操作成功!'
					});
					that.$refs.tables.loading = true
					that.GetBillLists()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
		th(id){
			let that = this

			this.$confirm('是否退还?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.bill.handle&method=back',{
					id:id}).
				then(function () {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message({
						type: 'success',
						message: '操作成功!'
					});
					that.$refs.tables.loading = true
					that.GetBillLists()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
		agreeChange(){
			this.$refs.tables.loading = true
			this.form = {}
			this.condition = {}
			this.currentPage = 1
			this.GetBillLists()
		},
		resetSearch(){
			this.$refs.tables.loading = true
			this.form = {}
			this.condition = {}
			this.currentPage = 1
			this.GetBillLists()
		},
		searchParamsChange(e) {
			this.$refs.tables.loading = true
			this.condition = e.condition
			this.currentPage = 1
			this.GetBillLists()
		},
		handleClick() {},
		GetBillLists(){
			let that = this
			this.dataOrigin.data = []
			let formData = this.$refs.tables.condition
			this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			that.$refs.tables && that.$refs.tables.initData()
			this.get('?server=live.bill.lists.get',{
				type: this.tabPosition,
				page:this.currentPage,
				zbtime: formData.zbtime,
				shop_name: formData.nick,
				typeInfo: formData.typeInfo,
				query:formData.contract_name}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				let len = response.data.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.data[i])
				}
				// that.dataOrigin.data = response.data.data
				that.total = response.data.total
				that.$refs.tables.total = response.data.total
				that.$refs.tables.loading = false
				that.$refs.tables.initData()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			this.$refs.tables.loading = true
			this.GetBillLists()
		});
	}
}
</script>